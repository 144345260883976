.Navbar {
  height: auto;

  .nav-link.active {
    color: var(--secondary-color) !important;
  }

  @media only screen and (min-width: 1000px) {
    height: 60px;
  }
  .container {
    max-width: 1400px !important;
  }
  .logo {
    max-width: 160px;
    max-height: 40px;
  }
  .navbar-text,
  a {
    color: var(--primary-color) !important;
  }
  .nav-link,
  .navbar-toggler,
  .user {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
    .btn {
      color: var(--secondary-color) !important;
    }
    color: white !important;
    &:hover {
      color: var(--secondary-color) !important;
    }
  }

  .nav-gear {
    .dropdown-toggle::after {
      display: none !important;
    }
    .dropdown-menu.show {
      left: -128px;
    }
  }
  .text-primary {
    color: black !important;
  }
}
