// $primary: #09a3a4;
// $secondary: #445f89;

// @import 'bootstrap/dist/css/bootstrap.min.css';
// $primary: #183059;

// $rafael: red;
// $theme-colors: (
//   "primary": #445f89,
//   "secondary": #445f89,
// );

// $primary: var(--primary-color);
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import "/src/assets/styles/bootstrap-utils.scss";


// @import "~ag-grid-community/styles//ag-grid.css";
// @import "~ag-grid-community/styles//ag-theme-quartz.css";
// @import "/src/assets/styles/bootstrap.css";

body {
  //--primary-color: #4c606a;
  --primary-color: #435559;
  --secondary-color: #f3ae3c;
  --tertiary-color: #2c221f;

  --background-color: #dee8ec;
}

$colors: "primary", "secondary", "tertiary";

@each $color in $colors {

  .btn-#{$color},
  .btn-#{$color}:focus,
  .btn-#{$color}:disabled,
  .btn-#{$color}:active,
  .btn-#{$color}:hover,
  .btn-check:checked+.btn-outline-#{$color},
  .btn-check:active+.btn-outline-#{$color},
  .btn-outline-#{$color}:active,
  .btn-outline-#{$color}:hover,
  .btn-outline-#{$color}.active,
  .btn-outline-#{$color}.dropdown-toggle.show {
    background-color: var(--#{$color}-color);
    border-color: rgba(var(--#{$color}-color), 1.5);

    .btn-check:focus+&,
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(var(--#{$color}-color), 0.5);
    }
  }

  .bg-#{$color} {
    background-color: var(--#{$color}-color) !important;
  }

  .text-#{$color},
  .btn-outline-#{$color} {
    color: var(--#{$color}-color) !important;
    // background-color: red !important;
    border-color: rgba(var(--#{$color}-color), 1.5);
  }

  .btn-check:checked+.btn-outline-#{$color},
  .btn-check:active+.btn-outline-#{$color},
  .btn-outline-#{$color}:active,
  .btn-outline-#{$color}.active,
  .btn-outline-#{$color}:hover,
  .btn-outline-#{$color}.dropdown-toggle.show {
    color: white !important;
  }
}

.tooltip-inner {
  text-align: start !important;
  max-width: 500px;
}

.rbt-token {
  color: var(--primary-color) !important;
  // color:var(--secondary-color) !important;
}

.max-98 {
  max-width: 98% !important;
}

.max-width-600 {
  max-width: 600px !important;
}

.click {
  cursor: pointer;
}

.stick {
  position: sticky !important;
  top: 60px;
  z-index: 26;

  .btn-sm {
    border: none;
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0.1);
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  /*-- black at 40% opacity --*/
}

::-webkit-resizer,
::-webkit-scrollbar-button,
::-webkit-scrollbar-corner {
  display: none;
}

h1,
h6 {
  font-family: "Poppins", Sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
}

h1 {
  font-size: 40px;
  line-height: 48px;
}

h6 {
  font-size: 14px;
  line-height: 24px;
}

table {
  td {
    vertical-align: middle;
    font-size: 11px;
  }
}

.download-bnt {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 2;
  border-radius: 30px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10px;
}

// .MatchingManual{
// 	  .tooltip-inner {
// 	max-width: 400px !important;
// 	background-color: red !important;
//   }
// }
// .MatchingManual > .tooltip-inner {
//   max-width: 400px !important;
//   background-color: red !important;
// }
.tooltip-inner {
  max-width: 800px !important;
}

.ag-theme-quartz {

  --ag-grid-size: 4px;
  
  .ag-paging-panel {
    justify-content: space-between !important;
  }

  .ag-header-container {
    background-color: #DEE8EC;

  }
}