.UserManager {
	// .card {
	// 	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	// }

	.form-check-input:checked {
		background-color: var(--secondary-color) !important;
		border-color: var(--secondary-color) !important;
	}

	.btn:disabled {
		opacity: 1 !important;
		height: 38px;
		display: flex;
		align-items: center;
	}

	.pr-0,
	.px-0 {
		padding-right: 0 !important;
	}
	.pl-0,
	.px-0 {
		padding-left: 0 !important;
	}

	.btn-group {
		.btn {
			border: 1px solid lightgray;
		}
	}

	.btn-check:checked + .btn-outline-primar {
		border: 1px solid var(--primary-color) !important;
	}

	.btn-sm {
		border: none !important;
	}


}
