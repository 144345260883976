
.Page404{

  .icon {
    width: 200px;
  height: 200px;
}

.error404 {
  margin: 0 auto;
  width: 420px;
}

  }