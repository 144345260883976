.container {
  max-width: 100% !important;
  padding: 0px !important ;
}
.main-layout {
  
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background-color: white;
  
}
footer {
  margin: 12px 0;
}
.height {
  height: calc(100vh - 60px);
}
.toast {
  display: flex;
  background: white !important;
  box-shadow: inset 5px 0px 0 #e41e25, 1px 1px 14px 2px rgba(0, 0, 0, 0.1) !important;
  position: relative;
  padding: 10px 0;
  padding-right: 30px;
  position: fixed;
  right: 10px;
  bottom: 20px;
  .toast-header {
    color: var(--bs-primary) !important;
    border-bottom: none !important;
    background-color: transparent !important;
    padding: 0;
    .btn-close {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .toast-body {
    padding: 0;
    // padding-top: 0;
    // padding-bottom: 0;
    font-size: 12px;
  }
}

// $types: "warning", "success", "info";

// @for $i from 1 through length($types) {
//   $length: nth($types, $i);

//   .toast-#{$length} {
//     box-shadow: inset 5px 0px 0var (--bs-warning),
//       1px 1px 14px 2px rgba(0, 0, 0, 0.1) !important;
//     .icons {
//       color: var(--bs-warning);
//     }
//   }
// }

.toast-success{
  box-shadow: inset 5px 0px 0 var(--bs-success), 1px 1px 14px 2px rgba(0, 0, 0, 0.1) !important;
  .icons {
    color: var(--bs-success);
  }
}

.toast-info{
  box-shadow: inset 5px 0px 0 var(--bs-info), 1px 1px 14px 2px rgba(0, 0, 0, 0.1) !important;
  .icons {
    color: var(--bs-info);
  }
}
.toast-warning{
  box-shadow: inset 5px 0px 0 var(--bs-warning), 1px 1px 14px 2px rgba(0, 0, 0, 0.1) !important;
  .icons {
    color: var(--bs-warning);
  }
}

.toast-container {
  z-index: 10000;
  // position: fixed !important;
  // right: 0;
}
