.MatchingValidations {
  .nav-link {
    color: var(--secondary-color);
  }

  .margin-top--1 {
    margin-top: -1px;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background-color: var(--secondary-color);
    color: white;
    font-weight: 600;
  }

  th:nth-child(2) {
    min-width: 90px !important;
    width: 90px !important;
    max-width: 90px !important;
  }

  th:nth-child(8) {
    min-width: 120px !important;
    width: 120px !important;
    max-width: 120px !important;
  }

  $widths: 5, 6, 7;

  @for $i from 1 through length($widths) {
    $width: nth($widths, $i);
    th:nth-child(#{$width}) {
      min-width: 100px !important;
      width: 100px !important;
      max-width: 100px !important;
    }
  }

  td:nth-child(3) {
    // background-color: palevioletred !important;
    border-right: 3px solid var(--primary-color);
  }



  @media only screen and (max-width: 1600px) {
    .group-item {
      // background-color: red;
      flex-direction: column;
      align-items: flex-start;
      .radios {
        width: 100%;
        display: flex;
      }
    }
  }

  .w-100 {
    width: 100% !important;
  }
  .my-1 {
    margin-top: 0.1rem !important;
    margin-bottom: 0.1rem !important;
  }
  .right-0 {
    right: 0px;
  }

  .search-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
  }
  .btn-light {
    color: gray;
    border-color: gray;
  }

  // .c-gray {
  //   .form-select {
  //     color: gray !important;
  //   }
  //   .option{
  //     color: red !important;
  //   }
  // }
  .btn-sm {
    border: none !important;
    padding: 0.1rem 0.25rem !important;
  }
  .bg-secondary {
    .btn-outline-secondary {
      color: white !important;
    }
  }
  .bg-ligthgreen {
    background-color: #e6ffe6;
  }
  .bg-lightred {
    background-color: #ffe6e6;
  }
 
}
.modal-product{

  th:nth-child(2) {
     min-width: 200px !important;
     width: 200px !important;
     max-width: 200px !important;
   }
 }