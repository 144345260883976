.table-row {
	text-align: center;
	font-size: 12px;
	min-width: 150px;
	background-color: var(--background-color) !important;
	position: relative;
	vertical-align: middle;
	.vertical {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: bold;
		color: var(--primary-color);
		height: auto;
	}
	.sort-absolute {
		position: absolute;
		right: 4px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.pointer {
	cursor: pointer;
}

.pointer:hover {
	.btn,
	.vertical {
		color: var(--secondary-color);
	}
	.pagination {
		justify-content: flex-end;
	}
}
