.BasePrice {
  #tabela {

    // margin-top: 10px;
    .table-responsive {
      .table-header {
        position: sticky;
        top: 0;
        z-index: 25;
        background-color: #E9ECEF;
        border: none;

        th {
          border: none;
        }
      }

      &::-webkit-scrollbar-thumb {
        height: 5px;
        background-color: var(--primary-color);
      }
    }
  }

  .granularidade-nivel,
  .granularidade {
    position: sticky;
    left: 0px;
    z-index: 2;
  }

  .granularidade-nivel::after,
  .granularidade::after {
    content: "";
    position: absolute;
    top: 0;
    right: -1px;
    /* Ajuste a posição da borda à direita */
    height: 100%;
    width: 1px;
    background-color: var(--bs-dark);
  }


  .granularidade-nivel::before,
  .granularidade::before {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    /* Ajuste a posição da borda à direita */
    height: 100%;
    width: 1px;
    background-color: #E9ECEF;

  }

  .drilldown {
    display: flex;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: center;

    .btn-sm {
      padding: 2px 4px !important;
    }

    .button:hover {
      color: var(--primary-color);
    }
  }

  .arrow-icon {
    position: relative;
    top: -2px;
  }

  .input-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  .type-of-product {
    position: absolute;
    top: 50%;
    left: 4px;
    font-size: 9px;
    transform: translateY(-50%);

  }


  th:nth-child(1) {
    background: #E9ECEF;
  }


  @for $i from 1 through 500 {
    .width-#{$i} {
      width: #{$i}px !important;
      min-width: #{$i}px !important;
    }
  }

  th,
  td {
    text-align: center;
  }

  .info-compradores {
    width: 12px;
    height: 12px;
    line-height: 6px;
    font-size: 6px;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
  }


  $gray-levels: 6;
  $base-color: #c7c7c7;
  
  @function get-gray-color($level) {
    $amount: ($level - 1) * 100 / ($gray-levels - 1);
    @return mix(#ffffff, $base-color, $amount);
  }
  
  @mixin generate-gray-classes() {
    @for $i from 1 through $gray-levels {
      .nivel-#{$i} {
        background-color: get-gray-color($i);
      }
    }
  }
  
  @include generate-gray-classes();

  $yellow-levels: 6;
  $base-yellow-color: #f7c07a;
  $lightest-yellow: #fff7e6;

  @function get-yellow-color($level) {
    $amount: ($level - 1) * 100 / ($yellow-levels - 1);
    @return mix($lightest-yellow, $base-yellow-color, $amount);
  }

  @mixin generate-yellow-classes() {
    @for $i from 1 through $yellow-levels {
      .nivel-yellow-#{$i} {
        background-color: get-yellow-color($i);
      }
    }
  }

  @include generate-yellow-classes();
}