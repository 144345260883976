.CustomerSegmentation {
	// .SelectSearch {
	//   width: 400px;
	// }

	.nav-link {
		color: var(--secondary-color);
	}

	.nav-tabs .nav-link.active,
	.nav-tabs .nav-item.show .nav-link {
		background-color: var(--secondary-color);
		color: white;
		font-weight: 600;
	}


	@media only screen and (max-width: 1600px) {
		.group-item {
			// background-color: red;
			flex-direction: column;
			align-items: flex-start;
			.radios {
				width: 100%;
				display: flex;
			}
		}
	}
}
